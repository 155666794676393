import React from 'react'

import digitalBankImage from '../../../images/new-home/da-pra-confiar-em-banco-digital.svg'
import { Title } from '../GlobalStyle'
import * as Styled from './styled'

const SectionBacen = () => (
  <Styled.BacenWrapper>
    <div className="container">
      <Styled.Description>
        <Title>
          Dá pra <span>confiar</span> em banco digital?
        </Title>
        <Styled.Infos>
          <span>Não precisa se preocupar: </span>seguimos todas as regulamentações<br />
          do <span>Banco Central (BACEN)</span> e nosso código bancário é o <span className="pink">403</span>.
          <br />
          <br />
          Isto quer dizer que somos uma instituição autorizada, e que seu dinheiro está seguro.
        </Styled.Infos>
      </Styled.Description>
      <Styled.Image src={digitalBankImage} alt="Dá pra confiar em banco digital?" />
    </div>
  </Styled.BacenWrapper >
)


export default SectionBacen
