import styled from 'styled-components'
import { device } from '../../../styles/breakpoints'

export const SocialWrapper = styled.section`
  background-color: #202020;
`

export const SocialList = styled.ul`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  height: 100px;

  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 30% repeat(5, 1fr);
    height: 208px;
  }

  @media ${device.laptopM} {
    grid-template-columns: 40% repeat(5, 1fr);
  }
`

export const SocialTitle = styled.h3`
  display: none;

  @media ${device.laptop} {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 3.2rem;
    font-weight: 400;
    justify-content: center;
    line-height: 8.5rem;
  }
`

export const SocialItem = styled.li`
  border-left: 1px solid #fff;
  display: flex;
`

export const SocialLink = styled.a`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  transition: all 0.5s;

  &:hover {
    background-color: #fe3e6d;
  }

  svg {
    width: 30px;
    height: 30px;

    @media ${device.laptop} {
      width: 45%;
      height: 64px;
    }
  }
`
