import styled from 'styled-components'

export const SocialWrapper = styled.ul`
  align-items: center;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(5, 35px);
  justify-content: flex-end;
`

export const SocialItem = styled.li``

export const Link = styled.a`
  display: inline-block;
  height: 32px;
  width: 32px;
`
