export const menus = [
  {
    title: 'Conta Digital',
    alt: 'Conta Digital',
    link: '/conta-pj-digital',
  },
  {
    title: 'Cartão',
    alt: 'Cartão',
    link: '/cartao-empresarial',
  },
  {
    title: 'Gestão de Cobranças',
    alt: 'Gestão de Cobranças',
    link: '/gestao-de-cobranca',
  },
  {
    title: 'Carreiras',
    alt: 'Carreiras',
    link: 'http://www.cora.com.br/vagas-cora',
  },
  {
    title: 'Boletos',
    alt: 'Boletos',
    link: '/boletos',
  },
  {
    title: 'Sala de Imprensa',
    alt: 'Sala de Imprensa',
    link: 'https://pj.cora.com.br/sala-de-imprensa/',
  },
  {
    title: 'Contabilidades',
    alt: 'Contabilidades',
    link: 'https://comunidade.cora.com.br/parceiros/',
  },
  {
    title: 'Ajuda',
    alt: 'Central de Ajuda',
    link: 'http://meajuda.cora.com.br',
  },
]
