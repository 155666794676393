import styled from 'styled-components'
import { device } from '../../../styles/breakpoints'

export const InvestorsWrapper = styled.section`
  transform: translateY(-30px);
  width: 100%;

  @media ${device.tablet} {
    transform: translateY(-60px);
  }

  @media ${device.laptop} {
    transform: translateY(-80px);
  }
`

export const InvestorsList = styled.div`
  grid-column: 1 / -1;

  .swiper-container {
    padding-bottom: 60px;
  }
`

export const InvestorsTitle = styled.h3`
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3rem;
  margin-bottom: 20px;

  @media ${device.tablet} {
    font-size: 2rem;
    line-height: 3.2rem;
    margin-bottom: 40px;
  }
`

export const InvestorsItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  @media ${device.laptop} {
    justify-content: flex-start;
  }
`

export const Image = styled.img`
  max-width: 100%;
`
