import React from 'react'
import ReactPlayer from 'react-player'

import * as Styled from './styled'

const Modal = ({ isOpen, handleModal, url }: IModal) => (
  <Styled.Modal isOpen={isOpen}>
    <Styled.ModalCloseBg onClick={() => handleModal()} />
    <Styled.ModalBody>
      <Styled.ModalCloseBtn title="Fechar Vídeo" onClick={() => handleModal()}>X</Styled.ModalCloseBtn>
      <Styled.ModalVideo>
        {isOpen && (
          <ReactPlayer
            controls={false}
            loop
            playing
            url={url}
            config={{
              youtube: {
                playerVars: { showinfo: 0 }
              }
            }}
          />
        )}
      </Styled.ModalVideo>
    </Styled.ModalBody>
  </Styled.Modal>
)


export default Modal
