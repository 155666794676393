import React from 'react'

import ServiceItem from '../ServiceItem'
import { data } from './data'

import * as Styled from './styled'

const SectionService = () => (
  <>
    <Styled.Services>
      <div className="container">
        <Styled.ServicesTitle>
          Quem <span>escolhe</span> a Cora pode contar com tudo isso:
        </Styled.ServicesTitle>
        <Styled.ServicesSubtitle>
          Conta PJ | Cartão | Gestão de Cobranças e Boletos
        </Styled.ServicesSubtitle>
      </div>
    </Styled.Services>
    {data.map((item: IServicesData, idx) => <ServiceItem key={`service_${idx}`} odd={(idx + 1) % 2 !== 0} data={item} />
    )}
  </>
)

export default SectionService
