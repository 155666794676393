export const newsData = [

  {
    link:
      'https://vocesa.abril.com.br/empreendedorismo/conheca-a-cora-uma-fintech-so-para-pjs/',
    image: 'logo-1-vcsa.png',
    altImage:
      'Conheça a Cora, uma fintech só para PJs',
  },
  {
    link:
      'https://revistapegn.globo.com/startups/noticia/2023/06/cora-banco-digital-para-pmes-entra-no-varejo-com-solucao-de-recebimento-de-pagamentos.ghtml',
    image: 'logo-2-pegn.png',
    altImage:
      'Cora, banco digital para PMEs, entra no varejo com solução de recebimento de pagamentos',
  },
  {
    link:
      'https://exame.com/invest/minhas-financas/fintech-cora-como-aprender-a-emprestar-em-tempos-de-juro-alto/',
    image: 'logo-3-exame.png',
    altImage:
      'Banco de PME: fintech Cora capta R$ 600 mi em aporte com Tiger e Tencent',
  },
  {
    link:
      'https://epocanegocios.globo.com/Startup/noticia/2020/06/fintech-cora-recebe-autorizacao-do-bc-para-se-tornar-instituicao-financeira.html',
    image: 'logo-4-epoca.png',
    altImage:
      'Fintech Cora recebe autorização do BC para se tornar instituição financeira',
  },
  {
    link:
      'https://www.cnnbrasil.com.br/economia/fintech-de-pmes-cora-aporte-116-mi/',
    image: 'logo-5-cnn.png',
    altImage:
      'Fintech de PMEs Cora recebe aporte de US$ 116 mi',
  },

]
