import styled, { keyframes } from 'styled-components'

import { device } from '../../../styles/breakpoints'
import { Title } from '../GlobalStyle'

export const PartnersWrapper = styled.section`
  padding: 80px 0 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  @media ${device.laptopM} {
    padding-bottom: 180px;
  }
`
export const PartnersTitle = styled(Title)``

export const Banner = styled.div`
  align-items: center;
  background: linear-gradient(180deg, #F0F4F8 0%, rgba(240, 244, 248, 0) 100%);
  display: flex;
  flex-direction: column-reverse;
  grid-column: 1 / -1;
  min-height: 850px;
  justify-content: center;
  padding: 60px 30px;
  position: relative;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }

  @media ${device.laptopM} {
    background: #f0f4f8;
    display: grid;
    grid-column: 3 / -1;
    grid-template-columns: 20% auto 10% 10%;
    padding: 0;
  }

  @media ${device.laptopL} {
    grid-column: 4 / -1;
  }
`

export const Description = styled.div`
  @media ${device.laptopM} {
    grid-column: 2 / 4;
    padding-right: 0;
  }
`

export const Infos = styled.div`
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  padding-bottom: 32px;

  span {
    color: #fe3e6d;
  }
`

export const PartnersItem = styled.li`
  margin: 10px 0;
`
export const Link = styled.a`
  img {
    filter: grayscale(100%);
    width: 150px;

    &:hover {
      filter: grayscale(0%);
    }
  }

`

const AnimateLink = keyframes`
  0% { transform: translateX(0);}
  50% { transform: translateX(10px);}
  100% { transform: translateX(0);}
`

export const AllPartners = styled(Link)`
  align-items: center;
  color: #fe3e6d;
  display: flex;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 3.2rem;
  text-decoration: none;
  transition: all 0.5s;
  gap: 5px;

  &:hover svg {
    animation: ${AnimateLink} 0.5s ease-in-out;
  }
`
