export const items = [
  {
    text: 'Sem filas e papelada.',
  },
  {
    text: 'Sem anuidade no cartão de Crédito e Débito.',
  },
  {
    text: 'Transferências PIX e TED gratuitas',
  },
  {
    text: 'Sem complicação pra cobrar e receber.',
  },
]
