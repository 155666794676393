import styled from 'styled-components'
import { device } from '../../../styles/breakpoints'

export const FooterWrapper = styled.footer`
  background-color: #3b3b3b;
  color: #fff;
`

export const FooterContent = styled.div`
  flex-direction: column;
  grid-column: 1/-1;
  padding: 50px 0;
  width: 100%;

  @media ${device.laptopM} {
    padding: 120px 0;
  }
`

export const FooterRow = styled.div`
  &:first-child {
    margin-bottom: 56px;
  }

  @media ${device.tabletL} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 24px;

    &:last-child {
      margin-top: 72px;
    }
  }
`

export const ListWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  :not(:last-child) {
    margin-bottom: 56px;
  }

  @media ${device.tabletL} {
    align-items: flex-start;

    &:nth-child(1),
    &:nth-child(3) {
      grid-column: 2/8;
    }

    &:nth-child(2),
    &:nth-child(4) {
      grid-column: 8/-1;
    }
  }

  @media ${device.laptopM} {
    &:nth-child(1) {
      grid-column: 2/4;
    }

    &:nth-child(2) {
      grid-column: 4/6;
    }

    &:nth-child(3) {
      grid-column: 6/9;
    }

    &:nth-child(4) {
      grid-column: 9/12;
    }

    &.store-list {
      grid-column: 2/6;
    }

    &.privacy-list {
      grid-column: 6/8;
    }

    &.ombudsman-list {
      grid-column: 9/12;
    }

    :not(:last-child) {
      margin-bottom: 0;
    }
  }
`

export const ListTitle = styled.h5`
  font-weight: 800;
  font-size: 2.2rem;
  line-height: 3.2rem;
  margin-bottom: 16px;

  &.store-title {
    margin-bottom: 24px;
  }
`

export const List = styled.ul`
  text-align: center;
  font-size: 1.6rem;
  line-height: 3.2rem;

  @media ${device.tabletL} {
    text-align: left;
    line-height: 4rem;
  }
`

export const Link = styled.a`
  color: #c7cbcf;
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-decoration: none;
  padding-bottom: 2px;

  &:hover {
    border-bottom: 2px solid #c7cbcf;
  }
`

export const FooterCopyright = styled.div`
  background-color: #fff;
  padding-bottom: 20px;

  @media ${device.tablet} {
    padding-bottom: 0;
  }
`

export const FooterCopyrightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.tabletL} {
    display: grid;
    flex-direction: row;
    grid-column: 1/-1;
    grid-template-columns: repeat(12, 1fr);
    padding: 12px 0;

    .left-content {
      grid-column: 2/10;
    }

    .right-content {
      grid-column: 10/12;
      align-items: center;
    }
  }
`

export const LinkStoresContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tabletL} {
    flex-direction: row;
  }
`

export const SmallText = styled.small`
  color: #b5b5b5;
  display: inline-flex;
  font-size: 12px;
  font-style: italic;
  line-height: 1.2;
  max-width: 80%;
`
