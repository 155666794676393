import React, { useState } from 'react'
import { Link } from 'gatsby'

import * as Styled from './styled'
import { menus } from './data'

const Menu = (): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false)
  const isExpanded = !!open
  const isHidden = !!open

  const linkProps = (href: string) => {
    const isExternal = /^http/.test(href)
    if (!isExternal) return

    return { target: '_blank', rel: 'noreferrer noopener' }
  }

  return (
    <Styled.MenuContainer>
      <Styled.MobileAnchor
        aria-label="Toggle menu"
        aria-expanded={isExpanded}
        open={open}
        onClick={() => setOpen(!open)}
      />
      <Styled.MenuContent aria-hidden={isHidden} className={open ? 'menu-open' : ''}>
        {menus.map(({ title, alt, link }: IMenus, idx) => (
          <li className="menu-item" key={`header-menu-${idx}`}>
            <Link to={link} activeClassName="active" title={alt || title} {...linkProps(link)}>
              {title}
            </Link>
          </li>
        )
        )}
      </Styled.MenuContent>
    </Styled.MenuContainer>
  )
}

export default Menu
