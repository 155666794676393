import React from 'react'

import { social } from './data';
import * as Styled from './styled'

const Social = ({ className }: SocialProps): JSX.Element => (
  <Styled.SocialWrapper className={className}>
    {
      social.map(({ link, icon, alt }: SocialNetwork, index: number) => (
        <Styled.SocialItem key={`social-newtwork-${index}`}>
          <Styled.Link href={link} title={alt}>
            {icon}
          </Styled.Link>
        </Styled.SocialItem>
      ))
    }
  </Styled.SocialWrapper>
);

export default Social;
