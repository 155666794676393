import styled from 'styled-components'
import { device } from '../../../styles/breakpoints'

export const Title = styled.h2`
  font-size: 3.2rem;
  font-weight: 400;
  line-height: 4rem;
  margin: 24px 0;
  text-align: left;

  span {
    color: #fe3e6d;
    font-weight: 700;
  }

  @media ${device.tablet} {
    font-size: 4.5rem;
    letter-spacing: 0px;
    line-height: 5.5rem;
  }

  @media ${device.laptopM} {
    font-size: 8rem;
    letter-spacing: 0px;
    line-height: 9.6rem;
    margin: 48px 0;
  }
`

export const Subtitle = styled.p`
  font-size: 2rem;
  letter-spacing: 0px;
  line-height: 2.5rem;
  text-align: left;

  @media ${device.tablet} {
    font-size: 2.5rem;
    line-height: 4rem;
  }

  @media ${device.laptopM} {
    font-size: 3.2rem;
    line-height: 4.8rem;
  }
`
