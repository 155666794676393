import styled from 'styled-components'
import { device } from '../../../styles/breakpoints'

export const NewsWrapper = styled.section`
  background: #fff;
  padding: 40px 0 0;
  width: 100%;

  .swiper-container {
    padding-bottom: 60px;
  }
`

export const NewsTitle = styled.h2`
  color: #6b7076;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 300;
  grid-column: 1 / -1;
  line-height: 4.8rem;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;

  @media ${device.tablet} {
    margin-bottom: 0;
    text-align: left;
  }

  @media ${device.laptopM} {
    font-size: 2.4rem;
    grid-column: 2 / -2;
    line-height: 5.6rem;
  }
`

export const NewsList = styled.div`
  grid-column: 1 / -1;
  padding: 0 0 30px;

  @media ${device.laptopM} {
    grid-column: 2 / -2;
  }
`
export const NewsItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  @media ${device.laptop} {
    justify-content: flex-start;
  }
`
export const Link = styled.a`
  img {
    width: 100%;
    height: auto;
  }
`
