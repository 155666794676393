import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { getCampaign, getSearchParam, hasCampaign } from "../../utils/getCampaign"

const growthCookieAB = (): void => {
  const [cookies, setCookie] = useCookies(['exp'])

  useEffect(() => {
    const experience = getSearchParam()
    const ad = getCampaign(experience)
    const expires = new Date(new Date().setDate(new Date().getDate() + 7))

    if (hasCampaign(ad) && !cookies?.exp) {
      setCookie('exp', experience, { path: '/', expires })
    }
  }, [])
}

export default growthCookieAB
