import styled from 'styled-components'
import { device } from '../../../styles/breakpoints'

export const BacenWrapper = styled.section`
  align-items: center;
  display: flex;
  padding: 80px 0;
  position: relative;
  transform: translateY(-5%);
  width: 100%;

  .container {
    position: relative;
    z-index: 2;
  }

  &:before {
    background: #f0f4f8;
    border-radius: 0px 8px 8px 0px;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    transform: translateX(-3%);
    width: 100%;

    @media ${device.laptop} {
      border-radius: 0px 25px 25px 0px;
      transform: translateX(-15%);
    }

    @media ${device.laptopL} {
      transform: translateX(-25%);
    }

    @media ${device.desktop} {
      transform: translateX(-35%);
    }
  }

  @media ${device.laptop} {
    padding: 100px 0;
    transform: translateY(-50px);
  }

  @media ${device.desktop} {
    padding: 100px 0;
    transform: translateY(-80px);
  }
`

export const Banner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  grid-column: 1 / -1;
  height: 800px;
  justify-content: center;
  padding: 0 30px;
  position: relative;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }

  @media ${device.laptopM} {
    display: grid;
    grid-column: 1 / 11;
    grid-template-columns: 20% auto 10% 10%;
    padding: 0;
  }

  @media ${device.laptopL} {
    grid-column: 1 / 10;
  }

  & > * {
    z-index: 2;
  }
`

export const Description = styled.div`
  grid-column: 1 / -1;

  @media ${device.tablet} {
    grid-column: 1 / 10;
  }

  @media ${device.laptop} {
    grid-column: 1 / 10;
  }

  @media ${device.laptopL} {
    grid-column: 2 / 9;
  }

  @media ${device.desktop} {
    grid-column: 2 / 7;
  }
`

export const Infos = styled.div`
  font-size: 2rem;
  font-weight: 400;
  line-height: 3.2rem;

  span {
    font-weight: 700;

    &.pink {
      color: #fe3e6d;
    }
  }
`

export const Image = styled.img`
  grid-column: 1 / -1;
  transform: translateY(20%);
  width: 200px;

  @media ${device.tablet} {
    grid-column: 10 / -1;
  }

  @media ${device.laptop} {
    width: 300px;
  }

  @media ${device.laptopM} {
    grid-column: 11 / -1;
    width: 390px;
  }

  @media ${device.laptopL} {
    grid-column: 9 / 12;
  }

  @media ${device.desktop} {
    grid-column: 7 / 10;
  }
`
