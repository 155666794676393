import React, { FC, ReactNode } from 'react';

import * as Styled from './styled';

interface ButtonProps {
  children: ReactNode
  disabled?: boolean
  fluid?: boolean
  handleClick?: () => void
  id?: string
  size?: 'small' | 'large'
  type?: 'button' | 'submit' | 'reset'
  mobileShadow?: boolean
}

const Button: FC<ButtonProps> = ({
  children,
  disabled = false,
  fluid = false,
  handleClick,
  id= '',
  type = 'button',
  size = 'small',
  mobileShadow = false
}: ButtonProps) => {
  return (
    <Styled.Button
      type={type}
      fluid={fluid}
      onClick={handleClick}
      id={id}
      disabled={disabled}
      size={size}
      mobileShadow={mobileShadow}
    >
      {children}
    </Styled.Button>
  )
};

export default Button;
