import React from 'react'
import SwiperCore, { Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import { useMediaQuery } from 'react-responsive'

import getStaticImage from '../../../hooks/getStaticImage'

import { slider } from './data'
import * as Styled from './styled'

const IsNotMobile = ({ children }: { children: JSX.Element }) => {
  const isNotMobile = useMediaQuery({ minWidth: 600 })

  return isNotMobile ? children : null
}

SwiperCore.use([Pagination, A11y])

const SectionHero = ({ handleFormModal }: HeroProps) => (
  <Styled.HeroWrapper>
    <Swiper
      slidesPerView={1}
      centeredSlides={true}
      spaceBetween={30}
      grabCursor={true}
      watchOverflow={true}
      pagination={{ clickable: true }}
      breakpoints={{
        768: {
          slidesPerView: 1,
          grabCursor: false,
        },
      }}
    >
      {slider.map(({ title, subtitle, image, btnText }: IHeroSlideProps, idx: number) => (
        <SwiperSlide key={`hero-slider-${idx}`}>
          <Styled.HeroBanner>
            <div className="container">
              <Styled.HeroBannerInfo>
                <Styled.HeroTitle>{title}</Styled.HeroTitle>
                <Styled.HeroSubTitle>{subtitle}</Styled.HeroSubTitle>
                <IsNotMobile>
                  <Styled.HeroButton onClick={handleFormModal}>{btnText}</Styled.HeroButton>
                </IsNotMobile>
              </Styled.HeroBannerInfo>
              <Styled.HeroBannerImage>
                <img src={getStaticImage(image)} style={{ width: '100%' }} />
              </Styled.HeroBannerImage>
            </div>
          </Styled.HeroBanner>
        </SwiperSlide>
      ))}
    </Swiper>
  </Styled.HeroWrapper>
)

export default SectionHero
