import React from 'react'
import { Link } from 'gatsby'

import Menu from '../Menu';
import Button from '../Button'

import growthCookieAB from '../../hooks/growthCookieAB'
import Logo from '../../images/logo-cora.svg'

import * as Styled from './styled'

const Header = ({ toggle }: HeaderProps) => {
  growthCookieAB()
  return (
    <Styled.Header>
      <div className="container">
        <Styled.HeaderContent>
          <Link to="/">
            <img src={Logo} alt="Logo da Cora" />
          </Link>
          <Menu />
          <Styled.LeftContent>
            <Styled.LinkLogin href="https://app.cora.com.br" target="_blank">Login</Styled.LinkLogin>
            <Button handleClick={toggle} size="small">
              Quero ser Cora
            </Button>
          </Styled.LeftContent>
        </Styled.HeaderContent>
      </div>
    </Styled.Header>
  );
}

export default Header;
