import React from 'react'

export const social = [
  {
    link: 'https://www.instagram.com/cora.bancopj/',
    alt: 'Instagram da Cora',
    icon: (
      <>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.99467 4H22.0067C22.7938 4 23.5733 4.15507 24.3005 4.45634C25.0278 4.75762 25.6885 5.1992 26.2451 5.75588C26.8016 6.31255 27.2431 6.97341 27.5442 7.70071C27.8453 8.42801 28.0002 9.2075 28 9.99467V22.0067C28 22.7938 27.8449 23.5733 27.5437 24.3005C27.2424 25.0278 26.8008 25.6885 26.2441 26.2451C25.6874 26.8016 25.0266 27.2431 24.2993 27.5442C23.572 27.8453 22.7925 28.0002 22.0053 28H9.99467C9.20739 28.0002 8.42779 27.8452 7.7004 27.544C6.97301 27.2428 6.3121 26.8013 5.75541 26.2446C5.19872 25.6879 4.75716 25.027 4.45596 24.2996C4.15476 23.5722 3.99982 22.7926 4 22.0053V9.99467C3.99982 9.20739 4.15476 8.42779 4.45596 7.7004C4.75716 6.97301 5.19872 6.3121 5.75541 5.75541C6.3121 5.19872 6.97301 4.75716 7.7004 4.45596C8.42779 4.15476 9.20739 3.99982 9.99467 4Z"
            stroke="#202020"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.5985 8.95061C22.5366 8.94603 22.4744 8.95429 22.4158 8.97488C22.3572 8.99547 22.3035 9.02795 22.2581 9.07028C22.2126 9.1126 22.1764 9.16386 22.1517 9.22084C22.1271 9.27782 22.1144 9.33929 22.1146 9.40139C22.1148 9.46349 22.1278 9.52488 22.1528 9.58171C22.1778 9.63854 22.2144 9.68958 22.26 9.73164C22.3057 9.77369 22.3596 9.80585 22.4183 9.8261C22.477 9.84634 22.5393 9.85423 22.6012 9.84928C22.7144 9.84022 22.82 9.78879 22.8968 9.70525C22.9737 9.62172 23.0163 9.51224 23.0159 9.39871C23.0156 9.28518 22.9724 9.17596 22.895 9.09288C22.8176 9.00981 22.7118 8.959 22.5985 8.95061Z"
            stroke="#202020"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.3947 12.6053C19.8405 13.0511 20.1941 13.5804 20.4353 14.1628C20.6766 14.7453 20.8008 15.3696 20.8008 16C20.8008 16.6305 20.6766 17.2547 20.4353 17.8372C20.1941 18.4196 19.8405 18.9489 19.3947 19.3947C18.9489 19.8405 18.4196 20.1941 17.8372 20.4353C17.2547 20.6766 16.6305 20.8008 16 20.8008C15.3696 20.8008 14.7453 20.6766 14.1628 20.4353C13.5804 20.1941 13.0511 19.8405 12.6053 19.3947C11.705 18.4943 11.1992 17.2732 11.1992 16C11.1992 14.7268 11.705 13.5057 12.6053 12.6053C13.5057 11.705 14.7268 11.1992 16 11.1992C17.2732 11.1992 18.4943 11.705 19.3947 12.6053Z"
            stroke="#202020"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </>
    ),
  },
  {
    link: 'https://www.linkedin.com/company/cora-bancopj/',
    alt: 'LinkedIn da Cora',
    icon: (
      <>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.8267 14.8V22M15.6253 22V17.8C15.6253 17.0044 15.9414 16.2413 16.504 15.6787C17.0666 15.1161 17.8297 14.8 18.6253 14.8C19.421 14.8 20.184 15.1161 20.7467 15.6787C21.3093 16.2413 21.6253 17.0044 21.6253 17.8V22M10 4H22.0053C22.7926 4 23.5721 4.15506 24.2994 4.45632C25.0267 4.75758 25.6875 5.19914 26.2442 5.7558C26.8009 6.31245 27.2424 6.9733 27.5437 7.70061C27.8449 8.42791 28 9.20744 28 9.99467V22.0067C27.9996 23.5963 27.3679 25.1207 26.2437 26.2447C25.1196 27.3686 23.595 28 22.0053 28H9.99467C8.40478 28 6.88002 27.3684 5.7558 26.2442C4.63158 25.12 4 23.5952 4 22.0053V10C4 8.4087 4.63214 6.88258 5.75736 5.75736C6.88258 4.63214 8.4087 4 10 4Z"
            stroke="#202020"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.8241 10.4502C10.7648 10.4505 10.7069 10.4683 10.6578 10.5014C10.6086 10.5345 10.5703 10.5815 10.5478 10.6363C10.5253 10.6912 10.5196 10.7514 10.5313 10.8095C10.543 10.8676 10.5717 10.921 10.6137 10.9628C10.6557 11.0046 10.7092 11.0331 10.7674 11.0445C10.8255 11.056 10.8858 11.05 10.9405 11.0272C10.9952 11.0045 11.042 10.966 11.0749 10.9167C11.1078 10.8674 11.1254 10.8095 11.1254 10.7502C11.1254 10.7107 11.1176 10.6716 11.1024 10.6351C11.0873 10.5986 11.0651 10.5655 11.0371 10.5376C11.0091 10.5097 10.9758 10.4876 10.9393 10.4727C10.9027 10.4577 10.8636 10.45 10.8241 10.4502"
            stroke="#202020"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </>
    ),
  },
  {
    link: 'https://www.facebook.com/cora.bancopj/',
    alt: 'Facebook da Cora',
    icon: (
      <>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.8 17.2H22M22 11.2H20.74C19.8011 11.2 18.9007 11.573 18.2368 12.2368C17.573 12.9007 17.2 13.8011 17.2 14.74V28M10 4H22.0053C22.7926 4 23.5721 4.15506 24.2994 4.45632C25.0267 4.75758 25.6875 5.19914 26.2442 5.7558C26.8009 6.31245 27.2424 6.9733 27.5437 7.70061C27.8449 8.42791 28 9.20744 28 9.99467V22.0067C27.9996 23.5963 27.3679 25.1207 26.2437 26.2447C25.1196 27.3686 23.595 28 22.0053 28H9.99467C8.40478 28 6.88002 27.3684 5.7558 26.2442C4.63158 25.12 4 23.5952 4 22.0053V10C4 8.4087 4.63214 6.88258 5.75736 5.75736C6.88258 4.63214 8.4087 4 10 4Z"
            stroke="#202020"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </>
    ),
  },
  {
    link: 'https://twitter.com/Cora_BancoPJ/',
    alt: 'Twitter da Cora',
    icon: (
      <>
        <svg
          id="Layer_1"
          x={0}
          y={0}
          viewBox="0 0 32 32"
          enableBackground="new 0 0 32 32"
          xmlSpace="preserve"
        >
          <path
            fill="none"
            stroke="#202020"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10 4h12c.8 0 1.6.2 2.3.5.7.3 1.4.7 1.9 1.3.6.6 1 1.2 1.3 1.9.3.7.5 1.5.5 2.3v12c0 .8-.2 1.6-.5 2.3-.3.7-.7 1.4-1.3 1.9-.6.6-1.2 1-1.9 1.3-.7.3-1.5.5-2.3.5H10c-.8 0-1.6-.2-2.3-.5-.7-.3-1.4-.7-1.9-1.3s-1-1.2-1.3-1.9c-.3-.7-.5-1.5-.5-2.3V10c0-.8.2-1.6.5-2.3.3-.7.7-1.4 1.3-1.9s1.2-1 1.9-1.3C8.4 4.2 9.2 4 10 4z"
          />
          <path
            fill="none"
            stroke="#202020"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M20.5 12.8c-.1-1.1-.9-1.9-1.9-2.1h-.8c-.3.1-.6.2-.8.3-.2.2-.5.4-.6.6 0 0 0 0 0 0l-6.8 9.7h5.5c3 0 5.5-2.4 5.5-5.5 0 0 0 0 0 0v-.6l1.9-1.5-2-.9z"
          />
          <circle cx={18.2} cy={13.5} r={0.6} fill="#202020" />
        </svg>
      </>
    ),
  },
  {
    link: 'https://www.youtube.com/@Cora.BancoPJ/',
    alt: 'Youtube da Cora',
    icon: (
      <>
        <svg
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 32 32"
          enableBackground="new 0 0 32 32"
          xmlSpace="preserve"
        >
          <path
            fill="none"
            stroke="#202020"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10,4h12c0.8,0,1.6,0.2,2.3,0.5c0.7,0.3,1.4,0.7,1.9,1.3c0.6,0.6,1,1.2,1.3,1.9C27.8,8.4,28,9.2,28,10v12  c0,0.8-0.2,1.6-0.5,2.3c-0.3,0.7-0.7,1.4-1.3,1.9c-0.6,0.6-1.2,1-1.9,1.3C23.6,27.8,22.8,28,22,28H10c-0.8,0-1.6-0.2-2.3-0.5  c-0.7-0.3-1.4-0.7-1.9-1.3s-1-1.2-1.3-1.9C4.2,23.6,4,22.8,4,22V10c0-0.8,0.2-1.6,0.5-2.3C4.8,7,5.2,6.3,5.8,5.8s1.2-1,1.9-1.3  C8.4,4.2,9.2,4,10,4z"
          ></path>
          <path
            fill="none"
            stroke="#202020"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.3,15.3l-8-4.8c-0.3-0.1-0.6-0.1-0.8,0c-0.2,0.1-0.4,0.4-0.4,0.7v9.6c0,0.3,0.1,0.6,0.4,0.7  c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1l8-4.8l0,0c0.2-0.2,0.4-0.4,0.4-0.7C21.7,15.7,21.5,15.4,21.3,15.3z"
          ></path>
        </svg>
      </>
    ),
  },
]
