import React, { useState } from 'react'
import Img from 'gatsby-image'

import getFluidImage from '../../../hooks/getFluidImage'
import * as Styled from './styled'
import Modal from '../Modal'

const VideoItem = ({ data, odd }: { data: IvideoItem, odd: boolean }) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { description, image, title, url, whiteText } = data
  const hasVideo = url.trim() !== ''
  const handleModal = () => hasVideo && setOpenModal(!openModal)

  return (
    <>
      <Modal isOpen={openModal} url={url} handleModal={handleModal} />
      <Styled.VideoWrapper odd={odd}>
        <Styled.VideoImage onClick={() => handleModal()} disabled={!hasVideo}>
          {<Img fluid={getFluidImage(image)} />}
        </Styled.VideoImage>
        <Styled.VideoInfo whiteText={whiteText}>
          {description && <Styled.VideoDesc>{description}</Styled.VideoDesc>}
          <Styled.VideoTitle>{title}</Styled.VideoTitle>
        </Styled.VideoInfo>
      </Styled.VideoWrapper>
    </>
  )
}

export default VideoItem
