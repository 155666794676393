import React, { FC } from 'react';

import * as S from './styled';

import ImageMobile from '../../images/new/play_store_mobile.png';
import ImageMobile2X from '../../images/new/play_store_mobile@2x.png';
import ImageTablet from '../../images/new/play_store_tablet.png';
import ImageTablet2X from '../../images/new/play_store_tablet@2x.png';
import ImageDesktop from '../../images/new/play_store_desktop.png';
import ImageDesktop2X from '../../images/new/play_store_desktop@2x.png';
import ImageNegativeMobile from '../../images/new/negative_play_store_mobile.png';
import ImageNegativeMobile2X from '../../images/new/negative_play_store_mobile@2x.png';
import ImageNegativeTablet from '../../images/new/negative_play_store_tablet.png';
import ImageNegativeTablet2X from '../../images/new/negative_play_store_tablet@2x.png';

interface AppStoreProps {
  className?: string
  negative?: boolean
  link?: string
}

const IconGooglePlay: FC<AppStoreProps> = ({ className, link, negative }) => (
  <S.GooglePlay
    href={link}
    className={className}
    target="_blank"
    rel="noopener noreferrer"
  >
    <picture>
      {negative ? (
        <>
          <source media="(min-width: 768px)" srcSet={`${ImageNegativeTablet}, ${ImageNegativeTablet2X} 2x`} />
          <img srcSet={`${ImageNegativeMobile}, ${ImageNegativeMobile2X} 2x`} alt="Ícone da Play Store" />
        </>
      ) : (
        <>
          <source media="(min-width: 1280px)" srcSet={`${ImageDesktop}, ${ImageDesktop2X} 2x`} />
          <source media="(min-width: 768px)" srcSet={`${ImageTablet}, ${ImageTablet2X} 2x`} />
          <img srcSet={`${ImageMobile}, ${ImageMobile2X} 2x`} alt="Ícone da Play Store" />
        </>
      )}
    </picture>
  </S.GooglePlay>
);

export default IconGooglePlay;
