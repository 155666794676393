import styled from 'styled-components';

export const GooglePlay = styled.a`
  display: block;
  margin-bottom: 24px;

  @media screen and (min-width: 768px) {
    margin-right: 24px;
    margin-bottom: 0;
  }
`;
