import React from 'react'
import { ArrowRight } from '@corabank/icons'

import { items } from './data'
import * as Styled from './styled'

const SectionHeroBar = () => (
  <Styled.HeroBar>
    <div className="container">
      <Styled.HeroBarTitle>
        Sua empresa livre <span>pra crescer</span>
      </Styled.HeroBarTitle>
      <Styled.HeroBarInfo>
        {items.map((item, idx) => (
          <Styled.ListItem key={`heroBar-${idx}`}>
            <ArrowRight color='#fe3e6d' size='medium' />
            {item.text}
          </Styled.ListItem>
        ))}
      </Styled.HeroBarInfo>
    </div>
  </Styled.HeroBar>
)


export default SectionHeroBar
