const size = {
  mobile: '400px',
  tablet: '600px',
  tabletL: '768px',
  laptop: '1024px',
  laptopM: '1200px',
  laptopL: '1360px',
  desktop: '1600px',
}

export const device = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletL: `(min-width: ${size.tabletL})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
}
