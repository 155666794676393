import React from 'react'

import VideoItem from '../VideoItem'
import { videosData } from './data'
import * as Styled from './styled'

const SectionVideos = () => (
  <>
    {videosData.map((video: IDataVideos, idx: number) => (
      <Styled.VideoSection key={`videos-${idx}`} bgMask={video.whiteText}>
        <div className="container">
          <VideoItem data={video} odd={(idx + 1) % 2 !== 0} />
        </div>
      </Styled.VideoSection>
    ))}
  </>
)

export default SectionVideos
