import React from 'react'
import SwiperCore, { Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'

import getStaticImage from '../../../hooks/getStaticImage'
import { newsData } from './data'
import * as Styled from './styled'

SwiperCore.use([Pagination, A11y])

const SectionNews = () => (
  <Styled.NewsWrapper>
    <div className="container">
      <Styled.NewsTitle>
        O que estão falando da gente:
      </Styled.NewsTitle>
      <Styled.NewsList>
        <Swiper
          slidesPerView={1}
          centeredSlides={true}
          spaceBetween={30}
          grabCursor={true}
          pagination={{ clickable: true }}
          watchOverflow={true}
          breakpoints={{
            700: {
              slidesPerView: 3,
              centeredSlides: false,
              grabCursor: false
            },
            1024: {
              slidesPerView: 4,
              centeredSlides: false,
              grabCursor: false
            },
            1440: {
              slidesPerView: 5,
              centeredSlides: false,
              grabCursor: false
            },
          }}
        >
          {newsData.map(({ link, image, altImage }: IDataNews, idx: number) => {
            return (
              <SwiperSlide key={`list-news-${idx}`}>
                <Styled.NewsItem>
                  <Styled.Link href={link} target="_blank" rel="noopener noreferrer">
                    <img src={getStaticImage(image)} alt={altImage} />
                  </Styled.Link>
                </Styled.NewsItem>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Styled.NewsList>
    </div>
  </Styled.NewsWrapper>
)


export default SectionNews
