import React from 'react'
import { ArrowRight } from '@corabank/icons'

import getStaticImage from '../../../hooks/getStaticImage'
import { data } from './data'
import * as Styled from './styled'

const SectionFeature = ({ handleFormModal }: IFeature) => (
  <Styled.FeatureCards>
    <div className="container">
      <Styled.FeatureCardTitle>
        Uma Conta Digital PJ completa, <span>não complexa</span>.
      </Styled.FeatureCardTitle>
      <Styled.CardList>
        {
          data.map((item: IFeatureData, idx: number) => (
            <Styled.Card key={`features-card-${idx}`}>
              <Styled.CardTitle>{item.title}</Styled.CardTitle>
              <Styled.CardDescription>
                {item.description}
              </Styled.CardDescription>
              <Styled.CardButton onClick={handleFormModal}>
                Abra sua conta <ArrowRight color="#202020" size="medium" />
              </Styled.CardButton>
              <Styled.CardIcon>
                <img src={getStaticImage(item.icon)} />
              </Styled.CardIcon>
            </Styled.Card>
          ))
        }
      </Styled.CardList>
    </div>
  </Styled.FeatureCards>
)

export default SectionFeature
