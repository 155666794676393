import { graphql, useStaticQuery } from 'gatsby'
import getImageHook from '../../utils/getImageHook'

export const getFluidImage = (filename: string) => {
  const { image } = useStaticQuery(
    graphql`
      query {
        image: allImageSharp {
          edges {
            node {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `
  )

  return getImageHook(image, filename, 'fluid')
}

export default getFluidImage
