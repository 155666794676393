import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

import { device } from '../../styles/breakpoints'
import { DefaultTheme } from './interface'

export const GlobalStyle = createGlobalStyle<{ theme: DefaultTheme }>`
  ${reset}

  html {
    font-size: 10px;
    scroll-behavior: smooth;
  }

  *, *::after, *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }

  body {
    background-color: ${({ theme }) => theme.bgColor};
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: 300;
    font-size: 1.6rem;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100vw;
    -webkit-overflow-scrolling: touch;
  }

  strong {
    font-weight: 800;
  }

  main {
    padding-top: ${({ theme }) => theme.paddingTop};

    @media ${device.laptopM} {
      padding-top: ${({ theme }) => theme.paddingTopDevice};
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: ${({ theme }) => theme.containerWidth};
    padding: 0 24px;
    width: 100%;

    @media ${device.tabletL} {
      display: grid;
      grid-gap: 24px;
      grid-template-columns: repeat(12, 1fr);
      padding: 0 32px;
    }

    @media ${device.laptopM} {
      padding: 0 64px;
    }

    &.container--no-mobile-margin {
      @media screen and (max-width: 420px) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  .swiper-pagination-bullet {
    background: #c7cbcf;
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  .swiper-pagination-bullet-active {
    background: #fe3e6d;
    width: 24px;
    border-radius: 6px;
  }
`
