import React from 'react'

export const slider = [
  {
    title: (
      <>
        Conta Digital PJ gratuita{' '}
        <span>feita por quem entende você e sua empresa.</span>
      </>
    ),
    subtitle: (
      <>
        Ágil, humana e sem tarifas abusivas. Abra sua conta Cora direto pelo
        app, livre de burocracia: <b>simples, rápido e fácil de usar</b>.
      </>
    ),
    btnText: 'Abra sua conta PJ grátis',
    image: 'hero-02.png',
  },
]
