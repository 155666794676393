import React from 'react'
import { ArrowRight } from '@corabank/icons'

import * as Styled from './styled'

const SectionPartners = () => (
  <Styled.PartnersWrapper>
    <Styled.Banner>
      <Styled.Description>
        <Styled.PartnersTitle>Contabilidades parceiras</Styled.PartnersTitle>
        <Styled.Infos>
          Conheça o <strong>programa Coraliados</strong> e descubra as nossas soluções que facilitam a relação entre escritórios de contabilidade e seus clientes.
          <br />
          <br />
          <strong>Se você é profissional contábil,</strong> torne-se membro e tenha acesso a benefícios exclusivos do banco Cora para escritórios de contabilidade, consultorias e BPOs financeiros.
          <br />
          <br />
          <strong>E para você, pessoa empreendedora,</strong> que está precisando de serviços de contabilidade, conheça nossos contadores parceiros e economize tempo com rotinas financeiras.
        </Styled.Infos>
        <Styled.AllPartners href="https://comunidade.cora.com.br/parceiros/" target="_blank">
          Conheça o programa Coraliados
          <ArrowRight size="medium" color="#FE3E6D" />
        </Styled.AllPartners>
      </Styled.Description>
    </Styled.Banner>
  </Styled.PartnersWrapper>
)


export default SectionPartners
