import React from 'react'
import Img from 'gatsby-image'
import { ArrowRight } from '@corabank/icons'

import getFluidImage from '../../../hooks/getFluidImage'
import * as Styled from './styled'

const ServiceItem = ({ data, odd }: IServicesItem) => {
  const { title, image, description, link, btnText, bgColor } = data
  return (
    <Styled.ServiceSection bgColor={bgColor}>
      <div className="container">
        <Styled.ServiceWrapper odd={odd}>
          <Styled.ServiceImageHolder>
            <Img fluid={getFluidImage(image)} />
          </Styled.ServiceImageHolder>
          <Styled.ServiceInfo>
            <Styled.ServiceTitle>{title}</Styled.ServiceTitle>
            <Styled.ServiceDesc>{description}</Styled.ServiceDesc>
            <Styled.ServiceLink href={link}>
              {btnText}
              <ArrowRight size="medium" color="#FE3E6D" />
            </Styled.ServiceLink>
          </Styled.ServiceInfo>
        </Styled.ServiceWrapper>
      </div>
    </Styled.ServiceSection>
  )
}

export default ServiceItem
