export const posts = [
  {
    link: 'https://www.cora.com.br/blog/cora-pro/',
    image: 'post-1.png',
    altImage: 'Imagem do cartão de crédito Cora',
    title: 'Conheça as vantagens do Cora Pro, o novo plano da Cora',
    date: '3 de agosto de 2023',
  },
  {
    link: 'https://www.cora.com.br/blog/domicilio-bancario-cora/',
    image: 'post-2.png',
    altImage: 'Imagem de uma mulher olhando para cima',
    title:
      'Novidade: centralize os pagamentos das suas maquininhas na Cora',
    date: '15 de junho de 2023',
  },
  {
    link: 'https://www.cora.com.br/blog/novidades-gestao-de-cobranca/',
    image: 'post-3-1.webp',
    altImage:
      'Imagem com um fundo rosa e uma pessoa negra olhando para o lado direito, no lado direito tem alguns desenhos gráficos',
    title: 'Conheça as novidades da Cora para facilitar a cobrança de seus clientes',
    date: '24 de abril de 2023',
  },
  {
    link: 'https://www.cora.com.br/blog/taxas-do-pix-cora/',
    image: 'post-4.webp',
    altImage:
      'Uma mulher negra dando risada segurando um copo de café com as duas mãos e uns desenhos gráficos do aldo direito com algumas inscrições com a palavra PIX',
    title: 'Entenda os motivos pelos quais a Cora não cobra taxas do PIX PJ',
    date: '28 de março de 2022',
  },
]
