export const social = [
  {
    alt: 'Siga a Cora no Instagram',
    link: 'https://www.instagram.com/cora.bancopj/',
    title: 'instagram',
  },
  {
    alt: 'Siga a Cora no Facebook',
    link: 'https://www.facebook.com/cora.bancopj/',
    title: 'facebook',
  },
  {
    alt: 'Siga a Cora no Twitter',
    link: 'https://twitter.com/Cora_BancoPJ/',
    title: 'twitter',
  },
  {
    alt: 'Siga a Cora no Linkedin',
    link: 'https://www.linkedin.com/company/cora-bancopj/',
    title: 'linkedin',
  },
  {
    alt: 'Assine nosso canal no Youtube',
    link: 'https://www.youtube.com/@Cora.BancoPJ/',
    title: 'youtube',
  },
]
