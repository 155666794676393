import React from 'react'
import Img from "gatsby-image"
import { ChevronRight } from '@corabank/icons'

import getFluidImage from '../../../hooks/getFluidImage'
import { posts } from './data'
import * as Styled from './styled'

const SectionPosts = () => (
  <Styled.PostsWrapper>
    <div className="container">
      <Styled.PostsTitle>Cora <span>Blog</span></Styled.PostsTitle>
      <Styled.PostList>
        {posts.map((item: IPostsData, idx: number) => {
          const { title, image, altImage, link, date } = item
          return (
            <Styled.Post key={`post-item-${idx}`} href={link} target="_blank" rel="noopener noreferrer">
              <Styled.PostImage>
                <Img fluid={getFluidImage(image)} alt={altImage} />
              </Styled.PostImage>

              <Styled.PostDescription>
                <Styled.PostTitle>{title}</Styled.PostTitle>
                <Styled.PostDate>{date}</Styled.PostDate>
              </Styled.PostDescription>

              <Styled.PostIcon>
                <ChevronRight size="medium" color="#fff" />
              </Styled.PostIcon>
            </Styled.Post>
          )
        })}
      </Styled.PostList>
    </div>
  </Styled.PostsWrapper>
)

export default SectionPosts
