import React, { FC } from 'react';

import * as Styled from './styled';

import ImageMobile from '../../images/new/app_store_mobile.png';
import ImageMobile2X from '../../images/new/app_store_mobile@2x.png';
import ImageTablet from '../../images/new/app_store_tablet.png';
import ImageTablet2X from '../../images/new/app_store_tablet@2x.png';
import ImageDesktop from '../../images/new/app_store_desktop.png';
import ImageDesktop2X from '../../images/new/app_store_desktop@2x.png';
import ImageNegativeMobile from '../../images/new/negative_app_store_mobile.png';
import ImageNegativeMobile2X from '../../images/new/negative_app_store_mobile@2x.png';
import ImageNegativeTablet from '../../images/new/negative_app_store_tablet.png';
import ImageNegativeTablet2X from '../../images/new/negative_app_store_tablet@2x.png';
import ImageNegativeDesktop from '../../images/new/negative_app_store_desktop.png';
import ImageNegativeDesktop2X from '../../images/new/negative_app_store_desktop@2x.png';

interface AppStoreProps {
  className?: string
  negative?: boolean
  link?: string
}

const IconAppStore: FC<AppStoreProps> = ({
  className,
  link = 'https://apps.apple.com/br/app/cora-empresas/id1494750820?l=pt&ls=1',
  negative
}: AppStoreProps) => (
  <Styled.AppStore
    href={link}
    className={className}
    target="_blank"
    rel="noopener noreferrer"
  >
    {negative ? (
      <picture>
        <source media="(min-width: 1280px)" srcSet={`${ImageNegativeDesktop}, ${ImageNegativeDesktop2X} 2x`} />
        <source media="(min-width: 768px)" srcSet={`${ImageNegativeTablet}, ${ImageNegativeTablet2X} 2x`} />
        <img srcSet={`${ImageNegativeMobile}, ${ImageNegativeMobile2X} 2x`} alt="Ícone da App Store" />
      </picture>
    ) : (
      <picture>
        <source media="(min-width: 1280px)" srcSet={`${ImageDesktop}, ${ImageDesktop2X} 2x`} />
        <source media="(min-width: 768px)" srcSet={`${ImageTablet}, ${ImageTablet2X} 2x`} />
        <img srcSet={`${ImageMobile}, ${ImageMobile2X} 2x`} alt="Ícone da App Store" />
      </picture>
    )}
  </Styled.AppStore>
);

export default IconAppStore;
