import styled, { css } from 'styled-components'
import { device } from '../../../styles/breakpoints'

const textColor = (
  isWhiteText: boolean | undefined,
  highlightColor: string
): string => (isWhiteText ? '#fff' : highlightColor)

export const VideoWrapper = styled.div<PositionProps>`
  align-items: center;
  display: grid;
  grid-column: 1 / -1;
  padding: 60px 0;

  @media ${device.laptopM} {
    padding: 100px 0;
  }

  @media ${device.tablet} {
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);

    > div {
      grid-column-start: ${({ odd }) => (odd ? 2 : 1)};
      margin-bottom: 0;
    }
  }

  @media ${device.laptopM} {
    grid-gap: 50px;
  }
`

const VideoImageHover = css`
  cursor: pointer;
  > div {
    background-color: #202020;
    transition: all 2s;
    width: 100%;

    & * {
      transition: all 2s;
    }
  }

  &::before,
  &::after {
    content: '';
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 1.5s;
  }

  &::before {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    height: 75px;
    position: absolute;
    width: 75px;
    z-index: 3;
  }

  &::after {
    border-bottom: 20px solid transparent;
    border-left: 25px solid #fff;
    border-top: 20px solid transparent;
    height: 0;
    left: calc(50% + 5px);
    width: 0;
    z-index: 4;
  }

  &:hover {
    &::before {
      background: rgba(0, 0, 0, 0.8);
    }

    &::before,
    &::after {
      transform: translate(-50%, -50%) scale(1.1);
    }

    & > div {
      transform: scale(1.1);

      & * {
        opacity: 0.5;
      }
    }
  }
`

export const VideoImage = styled.figure<DisabledProps>`
  align-items: center;
  border-radius: 15px;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;

  ${({ disabled }) => !disabled && VideoImageHover}
`

export const VideoInfo = styled.div<ColorProps>`
  color: ${({ whiteText }) => textColor(whiteText, '#3b3b3b')};
  display: flex;
  flex-direction: column-reverse;
  grid-row: ${({ whiteText }) => (whiteText ? '1 / 2' : '')};
  padding-left: ${({ whiteText }) => (whiteText ? '30px' : '0')};
  position: relative;
  z-index: 2;

  @media ${device.tablet} {
    flex-direction: column;
    grid-row-start: 1;
    padding-left: 0;
    text-align: left;
  }

  h3 {
    color: ${({ whiteText }) => textColor(whiteText, 'inherit')};

    span {
      color: ${({ whiteText }) => textColor(whiteText, '#fe3e6d')};
    }
  }
`

export const VideoTitle = styled.h3`
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3.2rem;

  @media ${device.tablet} {
    font-size: 3.5rem;
    line-height: 4.2rem;
  }

  @media ${device.laptop} {
    font-size: 4.8rem;
    line-height: 6rem;
  }

  @media ${device.laptopL} {
    font-size: 6.4rem;
    line-height: 8.8rem;
  }

  span {
    color: '#fe3e6d';
    font-weight: 700;
  }
`

export const VideoDesc = styled.p`
  font-size: 1.3rem;
  line-height: 2.2rem;
  font-weight: 400;
  margin: 20px 0;

  @media ${device.tablet} {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin: 0 0 20px;
  }

  @media ${device.laptop} {
    font-size: 2rem;
    line-height: 3.2rem;
  }

  @media ${device.laptopM} {
    margin-bottom: 40px;
  }

  b {
    font-weight: 700;
  }
`
