import React from 'react'

export const videosData = [
  {
    title: (
      <>
        Mais de <span>1 milhão</span> empresas são Cora
      </>
    ),
    description: (
      <>
        <b>Ainda tem dúvidas?</b> Vamos deixar quem
        <br /> confia na gente responder.
      </>
    ),
    image: 'thumb-400-mil-empresas-sao-cora.png',
    url: 'https://www.youtube.com/watch?v=QkjRODnXaVA&t',
    whiteText: true,
  },
  {
    title: (
      <>
        Os maiores <span>fundos de investimento</span> confiam na gente
      </>
    ),
    description: null,
    image: 'thumb-quem-investiu-nessa-ideia.png',
    url: '',
    whiteText: false,
  },
]
