import styled, { css } from 'styled-components'
import { device } from '../../../styles/breakpoints'
import { Title } from '../GlobalStyle'

const lightCard = css`
  background-color: #fcc0ca;
  border: 1px solid #fcc0ca;
`

const darkCard = css`
  background-color: #fd7b94;
  border: 1px solid #fd7b94;
`

export const FeatureCards = styled.section`
  padding: 60px 0;
  width: 100%;

  @media ${device.laptopM} {
    padding: 120px 0;
  }
`

export const FeatureCardTitle = styled(Title)`
  grid-column: 1 / 9;

  @media ${device.laptopM} {
    grid-column: 2 / 11;
  }
`

export const CardList = styled.div`
  display: grid;
  grid-column: 1 / -1;
  grid-gap: 25px;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.laptopM} {
    grid-column: 2 / -2;
  }
`
export const CardButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: 0;
  color: #3b3b3b;
  cursor: pointer;
  display: flex;
  font-family: 'Inter';
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 900;
  line-height: 2.4rem;
  text-align: center;

  svg {
    transition: all 0.5s;
  }
`

export const Card = styled.div`
  ${lightCard}
  align-items: flex-start;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 25px 25px 0;
  transition: all 0.5s;
  width: 100%;

  &:nth-child(2n + 2) {
    ${darkCard}
  }

  @media ${device.tablet} {
    &:nth-child(3) {
      ${darkCard}
    }

    &:nth-child(4) {
      ${lightCard}
    }
  }

  &:hover {
    transform: translate(-5px, -5px) scale(1.05);

    ${CardButton} svg {
      transform: translateX(10px);
    }
  }

  @media ${device.laptopM} {
    border-radius: 24px;
    padding: 40px 40px 0;
  }
`

export const CardTitle = styled.h3`
  color: #202020;
  font-weight: 800;
  font-size: 2.2rem;
  line-height: 3.2rem;

  @media ${device.laptopM} {
    font-size: 3.2rem;
    line-height: 4rem;
  }
`

export const CardDescription = styled.div`
  color: #3b3b3b;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  margin: 40px 0;
`

export const CardIcon = styled.figure`
  align-items: flex-end;
  bottom: 0;
  display: flex;
  height: 160px;
  pointer-events: none;
  right: 0;
  transform: translateX(40px);
  width: 100%;
  justify-content: flex-end;

  @media ${device.laptopM} {
    height: 220px;
  }

  img {
    height: 100%;
    object-fit: contain;
    width: auto;
  }
`
