import styled from 'styled-components'
import { device } from '../../../styles/breakpoints'

export const HeroBar = styled.section`
  align-items: center;
  background: #202020;
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  transform: translateY(-40px);

  @media ${device.tablet} {
    transform: translateY(0);
  }

  .container {
    align-items: center;
  }
`

export const HeroBarTitle = styled.h2`
  color: #fff;
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 400;
  grid-column: 1 / -1;
  line-height: 4rem;
  width: 100%;

  @media ${device.tablet} {
    font-size: 4.2rem;
  }

  @media ${device.laptopL} {
    font-size: 5.4rem;
    line-height: 6.8rem;
    grid-column: 2 / 6;
  }

  @media ${device.desktop} {
    padding-right: 15%;
  }

  span {
    color: #fe3e6d;
  }
`

export const HeroBarInfo = styled.ul`
  display: grid;
  grid-column: 1 / -1;
  grid-gap: 20px;
  padding: 30px 0;
  width: 100%;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.laptopL} {
    grid-column: 6 / -2;
  }

  @media ${device.desktop} {
    padding: 30px 50px;
  }
`

export const ListItem = styled.li`
  align-items: flex-start;
  color: #fff;
  display: grid;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 300;
  grid-gap: 10px;
  grid-template-columns: 30px auto;
  line-height: 1.5;

  @media ${device.laptopL} {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.8rem;
  }
`
