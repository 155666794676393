import React from 'react'
import { ThemeProvider } from 'styled-components'

import Header from '../Header'
import Footer from '../Footer'
import { NewFooter } from '../../partials/HomeNew/Components'

import { GlobalStyle } from './GlobalStyle'
import { LayoutProps } from './interface'
import { themes } from './themes'

const Layout = ({
  pageType,
  children,
  toggle,
  model = 'old',
  isWebView = false,
}: LayoutProps): JSX.Element => {
  const storeLink = `https://app.adjust.com/4uz2fax?campaign=${pageType}&engagement_type=fallback_click`
  const theme = themes as any

  const footerLayout = {
    old: <Footer storeLink={storeLink} />,
    new: <NewFooter storeLink={storeLink} />,
  } as any

  const currentTheme = {
    ...theme[model],
    paddingTop: isWebView ? '0' : '64px',
    paddingTopDevice: isWebView ? '0' : '88px',
  }

  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyle />
      {!isWebView && <Header toggle={toggle} />}
      <main>{children}</main>
      {!isWebView && footerLayout[model]}
    </ThemeProvider>
  )
}

export default Layout
