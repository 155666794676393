import styled, { keyframes } from 'styled-components'
import { device } from '../../../styles/breakpoints'

export const ServiceSection = styled.section<BackgroundProps>`
  background-color: ${({ bgColor }) => bgColor};
  padding: 60px 0;

  @media ${device.laptopM} {
    padding: 140px 0;
  }
`

export const ServiceWrapper = styled.div<PositionProps>`
  align-items: center;
  display: grid;
  grid-column: 1 / -1;

  figure {
    justify-content: center;
    margin-bottom: 30px;
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);

    > div {
      grid-column-start: ${({ odd }) => (odd ? 2 : 1)};
    }

    figure {
      justify-content: ${({ odd }) => (odd ? 'flex-start' : 'flex-end')};
      margin-bottom: 0;
    }
  }

  @media ${device.laptopM} {
    grid-template-columns: ${({ odd }) => (odd ? '60% 40%' : '40% 60%')};
  }
`

export const ServiceImageHolder = styled.figure`
  align-items: center;
  display: flex;

  @media ${device.laptopM} {
    min-height: 400px;
    width: 100%;
  }

  > div {
    width: 100%;
  }
`
export const ServiceInfo = styled.div`
  @media ${device.tablet} {
    grid-row-start: 1;
  }
`

export const ServiceTitle = styled.h3`
  font-size: 2.4rem;
  font-weight: 800;
  line-height: 3.2rem;
  margin-bottom: 30px;

  @media ${device.laptopM} {
    font-size: 3.2rem;
    line-height: 4.8rem;
  }

  @media ${device.laptopL} {
    font-size: 4.8rem;
    line-height: 6.4rem;
  }
`

export const ServiceDesc = styled.p`
  color: #3b3b3b;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  margin-bottom: 30px;

  @media ${device.laptop} {
    font-size: 2.2rem;
    line-height: 3rem;
  }

  span {
    font-weight: 700;
  }
`
const AnimateLink = keyframes`
  0% { transform: translateX(0);}
  50% { transform: translateX(10px);}
  100% { transform: translateX(0);}
`

export const ServiceLink = styled.a`
  align-items: center;
  color: #fe3e6d;
  display: inline-flex;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
  text-decoration: none;
  transition: all 0.5s;

  @media ${device.tablet} {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }

  &:hover svg {
    animation: ${AnimateLink} 0.5s ease-in-out;
  }
`
