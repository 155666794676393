import styled from 'styled-components'

export const Modal = styled.div<ModalProps>`
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  min-height: 100vh;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  position: fixed;
  top: 0;
  transition: all 0.5s;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  width: 100%;
  z-index: 200;
`
export const ModalCloseBtn = styled.button`
  background: black;
  border-radius: 5px;
  border: 2px solid;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  height: 30px;
  line-height: 1;
  position: absolute;
  right: 0px;
  top: -40px;
  width: 30px;
  z-index: 6;
`

export const ModalCloseBg = styled.div`
  height: 100%;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
`
export const ModalBody = styled.div`
  background-color: #fff;
  border-radius: 5px;
  left: 50%;
  max-width: 1200px;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  z-index: 10;
`

export const ModalVideo = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`
