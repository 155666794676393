import React from 'react'
import SwiperCore, { Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'

import getStaticImage from '../../../hooks/getStaticImage'
import { investorsData } from './data'
import * as Styled from './styled'

SwiperCore.use([Pagination, A11y])

const SectionInvestors = (): JSX.Element => (
  <Styled.InvestorsWrapper>
    <div className="container">
      <Styled.InvestorsList>
        <Styled.InvestorsTitle>Conheça nossos investidores</Styled.InvestorsTitle>
        <Swiper
          slidesPerView={1}
          centeredSlides={true}
          spaceBetween={30}
          grabCursor={true}
          watchOverflow={true}
          pagination={{ clickable: true }}
          breakpoints={{
            700: {
              slidesPerView: 3,
              centeredSlides: false,
              grabCursor: false
            },
            1024: {
              slidesPerView: 4,
              centeredSlides: false,
              grabCursor: false
            },
            1440: {
              slidesPerView: 6,
              centeredSlides: false,
              grabCursor: false
            },
          }}
        >
          {
            investorsData.map((item: IDataInvestors, id: number) => {
              const { image, altImage } = item
              return (
                <SwiperSlide key={`list-investors-${id}`}>
                  <Styled.InvestorsItem>
                      <Styled.Image src={getStaticImage(image)} alt={altImage} />
                  </Styled.InvestorsItem>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </Styled.InvestorsList>
    </div>
  </Styled.InvestorsWrapper>
)

export default SectionInvestors
