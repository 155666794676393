import styled from 'styled-components'
import { device } from '../../../styles/breakpoints'

export const HeroWrapper = styled.section`
  align-items: center;
  background: #fe3e6d;
  display: flex;
  min-height: calc(100vh - 60px);
  position: relative;

  @media ${device.tablet} {
    min-height: calc(100vh - 64px);
  }

  @media ${device.laptopL} {
    min-height: calc(100vh - 84px);
  }

  .swiper-pagination-bullet {
    background: #fff;
  }

  .swiper-pagination-bullet-active {
    background: #000;
  }
`

export const HeroBanner = styled.div`
  position: relative;
  width: 100%;
`

export const HeroBannerInfo = styled.div`
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  justify-content: center;
  padding: 30px 0;
  text-align: left;

  @media ${device.tablet} {
    align-items: flex-start;
    grid-column: 1 / 7;
    height: 100%;
    justify-content: center;
    padding: 0;
  }
`

export const HeroTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3.8rem;

  @media ${device.laptop} {
    font-size: 3.5rem;
    line-height: 4.6rem;
  }

  @media ${device.laptopM} {
    font-size: 4rem;
    line-height: 5.6rem;
  }

  @media ${device.desktop} {
    font-size: 6.5rem;
    line-height: 8rem;
  }

  span {
    color: black;
    font-weight: 400;
  }
`

export const HeroSubTitle = styled.p`
  font-size: 1.8rem;
  line-height: 1.2;
  font-weight: 400;
  margin: 30px 0;

  & b {
    font-weight: 700;
  }

  @media ${device.laptopL} {
    font-size: 2.5rem;
    line-height: 3rem;
  }
`

export const HeroButton = styled.button`
  background-color: #000;
  border-radius: 30px;
  border: 0;
  bottom: 50px;
  color: #fff;
  cursor: pointer;
  font-family: 'Inter';
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 2.2rem;
  padding: 12px 32px;
  position: absolute;
  transition: all 0.5s;
  width: initial;
  z-index: 10;

  @media ${device.tabletL} {
    position: initial;
  }

  &:hover {
    background-color: #fff;
    color: #000;
  }
`

export const HeroBannerImage = styled.div`
  align-items: center;
  display: flex;

  @media ${device.tablet} {
    padding: 0 8%;
    grid-column: 7 / -1;
  }

  @media ${device.laptopL} {
    grid-column: 7 / -1;
  }

  div {
    width: 100%;
  }
`
