import React from 'react'

import { social } from './data'
import { Facebook, Instagram, Linkedin, Twitter, Youtube } from './icons'
import * as Styled from './styled'

const SectionSocial = () => {
  const icons = {
    instagram: <Instagram />,
    facebook: <Facebook />,
    twitter: <Twitter />,
    linkedin: <Linkedin />,
    youtube: <Youtube />
  } as any

  return (
    <Styled.SocialWrapper>
      <Styled.SocialList>
        <Styled.SocialTitle>Siga a Cora</Styled.SocialTitle>
        {social.map((item: ISocialData) => {
          const { title, link, alt } = item
          return (
            <Styled.SocialItem key={`social-${title}`}>
              <Styled.SocialLink href={link} rel="noopener noreferrer" target="_blank" title={alt}>
                {icons[title]}
              </Styled.SocialLink>
            </Styled.SocialItem>
          )
        })}
      </Styled.SocialList>
    </Styled.SocialWrapper>
  )
}

export default SectionSocial
