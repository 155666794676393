export const themes = {
  old: {
    bgColor: '#FAFAFA',
    fontFamily: 'Nunito, sans-serif',
    containerWidth: '1920px',
  },
  new: {
    bgColor: '#FFF',
    fontFamily: 'Inter',
    containerWidth: '1700px',
  },
}
