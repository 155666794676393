export const investorsData = [
  {
    image: 'logo-kazek.png',
    altImage: 'Logo da empresa Kazek, investidora da Cora',
  },
  {
    image: 'logo-ribbit.png',
    altImage: 'Logo da empresa Ribbit Capital, investidora da Cora',
  },
  {
    image: 'logo-tencent.png',
    altImage: 'Logo da empresa Tencent, investidora da Cora',
  },
  {
    image: 'logo-green-oaks.png',
    altImage: 'Logo da empresa Green Oaks, investidora da Cora',
  },
  {
    image: 'logo-qed.png',
    altImage: 'Logo da empresa QED, investidora da Cora',
  },
  {
    image: 'logo-tiger-global.png',
    altImage: 'Logo da empresa Tiger Global, investidora da Cora',
  },
]
