export const data = [
  {
    title: 'Libertamos você da burocracia',
    description:
      'Faça seu cadastro em nosso app. É rapidinho e pode ficar pronto em até 7 minutos.',
    icon: 'feat-burocracia.png',
  },
  {
    title: 'Robô que funciona ou atendimento humano de verdade? Você escolhe',
    description:
      'Tire dúvidas simples com o BOTo, nosso assistente virtual ou, se preferir, fale com nossa equipe. Tudo com o atendimento que você merece.',
    icon: 'feat-boto.png',
  },
  {
    title: 'Sem taxas abusivas nos serviços realizados via aplicativo',
    description: 'Transferências TED e Pix gratuitas e emissão gratuita de boletos direto no app. Pague apenas R$1,90 por boleto, a partir da 100ª cobrança compensada',
    icon: 'feat-taxas.png',
  },
  {
    title: 'Profissionalizamos ainda mais sua empresa',
    description: `Emita boletos personalizados, com o nome da sua empresa. `,
    icon: 'feat-profissionalismo.png',
  },
]
