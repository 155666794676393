import styled from 'styled-components'
import { device } from '../../styles/breakpoints'

export const Header = styled.header`
  background-color: #fff;
  border-bottom: 2px solid #f0f4f8;
  display: flex;
  height: 64px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;

  .container {
    max-width: 1920px;
  }

  @media ${device.tabletL} {
    height: 72px;
  }

  @media ${device.laptop} {
    height: 88px;
  }
`

export const HeaderContent = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;

  @media ${device.tabletL} {
    grid-column: 1/-1;
  }
`

export const LeftContent = styled.div`
  display: none;

  @media ${device.tabletL} {
    align-items: center;
    display: flex;
    margin-left: auto;
  }

  @media ${device.laptop} {
    order: 3;
  }
`

export const LinkLogin = styled.a`
  color: #202020;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.4rem;
  margin-right: 40px;
  text-decoration: none;

  :hover {
    color: #fe3e6d;
  }

  @media ${device.laptop} {
    margin-right: 20px;
  }
`
