import styled, { css } from 'styled-components'
import { device } from '../../../styles/breakpoints'

const cssMask = css`
  &::before {
    background-color: #fe3e6d;
    border-radius: 24px 0px 0px 24px;
    content: '';
    height: 100%;
    position: absolute;
    transform: translateX(10%);
    width: 100%;

    @media ${device.laptopL} {
      transform: translateX(40%);
    }
  }
`

export const VideoSection = styled.section<HasMaskProps>`
  overflow: hidden;
  position: relative;
  width: 100%;

  ${({ bgMask }) => (bgMask ? cssMask : '')}
`

export const Videos = styled.div`
  grid-column: 1 / -1;
  position: relative;
  z-index: 2;
`
