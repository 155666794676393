export const getSearchParam = (): string | null => {
  const params = new URLSearchParams(location.search)
  return params.get('utm_campaign')
}

export const hasCampaign = (campaign: string | undefined): boolean => {
  if (!campaign) return false

  const experiments = ['A', 'B']
  return experiments.includes(campaign)
}

export const getCampaign = (campaign: string | null): string | undefined =>
  campaign?.substring(campaign?.indexOf('ad1:') + 4, campaign?.length - 1)
