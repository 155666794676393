
import styled from 'styled-components';

interface MenuStyledProps {
  open: boolean
}

export const MenuContainer = styled.nav`
  margin-left: auto;
  order: 2;

  @media screen and (min-width: 768px) {
    order: 3;
    margin-left: 29px;
  }

  @media screen and (min-width: 1280px) {
    order: 2;
    margin-left: 62px;
  }
`

export const MobileAnchor = styled.div<MenuStyledProps>`
  height: 24px;
  position: relative;
  width: 24px;

  :before,
  :after {
    background-color: #FE3E6D;
    content: '';
    height: 1px;
    position: absolute;
    transition: transform .3s ease-in-out;
  }

  :before {
    left: 0;
    top: 8px;
    width: 100%;
    transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
  }

  :after {
    top: ${({ open }) => (open ? '8px' : '16px')};
    right: 0;
    width: ${({ open }) => (open ? '100%' : '50%')};
    transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
  }

  @media screen and (min-width: 1400px) {
    display: none;
  }
`;

export const MenuContent = styled.ul`
  position: absolute;
  height: 0;
  width: 100%;
  left: 0;
  background-color: #FFF;
  top: 64px;
  z-index: 2;
  flex-direction: column;
  padding: 24px;
  visibility: hidden;
  transition: height .5s ease-in;
  transition-delay: .2s;

  @media screen and (min-width: 1024px) {
    top: 87px;
  }

  @media screen and (min-width: 1400px) {
    display: flex;
    position: relative;
    visibility: visible;
    opacity: 1;
    height: 24px;
    top: 0;
    flex-direction: row;
    padding: 0;
  }

  .menu-item {
    transform: scale(1.15) translateY(-30px);
    opacity: 0;
    transition: transform .5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity .6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition-delay: .7s;

    a {
      color: #202020;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 24px;
      text-decoration: none;

      @media screen and (min-width: 768px) {
        color: #202020;
        transform: all .3s ease-in-out;
      }

      &.active {
        font-weight: 800;
        color: #FE3E6D;
      }
    }

    :hover {
      a {
        color: #FE3E6D;
      }
    }

    :not(:first-child) {
      margin-top: 24px;
    }

    @media screen and (min-width: 1400px) {
      opacity: 1;
      transform: scale(1) translateY(0);
      transition: none;

      :not(:first-child) {
        margin-left: 24px;
        margin-top: 0;
      }
    }
  }

  &.menu-open {
    height: 100vh;
    visibility: visible;
    transition: all .3s ease-in;
    transition-delay: .3s;

    .menu-item {
      transform: scale(1) translateY(0px);
      opacity: 1;

      &:nth-child(1) {
        transition-delay: .3s;
      }

      &:nth-child(2) {
        transition-delay: .4s;
      }

      &:nth-child(3) {
        transition-delay: .5s;
      }

      &:nth-child(4) {
        transition-delay: .6s;
      }

      &:nth-child(5) {
        transition-delay: .7s;
      }

      &:nth-child(6) {
        transition-delay: .8s;
      }

      &:nth-child(7) {
        transition-delay: .9s;
      }

      &:nth-child(8) {
        transition-delay: 1s;
      }
      
    }
  }
`;
