import styled from 'styled-components'

import { Title, Subtitle } from '../GlobalStyle'
import { device } from '../../../styles/breakpoints'

export const Services = styled.section`
  padding: 60px 0;
  width: 100%;

  @media ${device.laptopM} {
    padding: 80px 0;
  }
`

export const ServicesTitle = styled(Title)`
  grid-column: 1 / 9;

  @media ${device.tablet} {
    grid-column: 1 / 11;
  }

  @media ${device.laptop} {
    grid-column: 1 / 9;
  }

  @media ${device.laptopM} {
    grid-column: 2 / 12;
  }
`

export const ServicesSubtitle = styled(Subtitle)`
  display: none;

  @media ${device.tablet} {
    display: initial;
    grid-column: 1 / 12;
  }

  @media ${device.laptopM} {
    grid-column: 2 / 10;
  }
`
