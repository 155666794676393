import styled from 'styled-components'

import { Title } from '../GlobalStyle'
import { device } from '../../../styles/breakpoints'

export const PostsWrapper = styled.section`
  background: linear-gradient(180deg, #f0f4f8 0%, rgba(240, 244, 248, 0) 100%);
  padding: 60px 0;

  @media ${device.laptopM} {
    padding: 145px 0 215px;
  }
`
export const PostsTitle = styled(Title)`
  grid-column: 1 / -1;
`

export const PostList = styled.div`
  grid-column: 1 / -1;
  margin-top: 50px;
`

export const PostIcon = styled.div`
  display: none;

  @media ${device.tablet} {
    align-items: center;
    background-color: #fe3e6d;
    border-radius: 50%;
    display: flex;
    height: 28px;
    justify-content: center;
    transition: all 0.5s;
    width: 28px;
  }
`

export const Post = styled.a`
  display: block;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media ${device.tablet} {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 40% auto 30px;

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    &:hover ${PostIcon} {
      transform: translateX(10px);
    }
  }
`

export const PostImage = styled.figure`
  border-radius: 25px;
  margin-bottom: 30px;
  overflow: hidden;

  @media ${device.tablet} {
    margin-bottom: 0;
    min-height: 200px;
  }

  @media ${device.tabletL} {
    min-height: 250px;
  }

  @media ${device.laptop} {
    min-height: 300px;
  }

  > div {
    height: 100%;
    width: 100%;
  }
`

export const PostDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const PostTitle = styled.h3`
  color: #202020;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3.2rem;
  margin-bottom: 30px;

  @media ${device.laptop} {
    font-size: 3.5rem;
    line-height: 4.8rem;
    margin-bottom: 0;
  }

  @media ${device.laptopM} {
    font-size: 4.8rem;
    line-height: 6.4rem;
  }
`

export const PostDate = styled.span`
  color: #fe3e6d;
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 2.4rem;
`
